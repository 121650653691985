import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCardFeed, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in pagination.postsPerPage in siteSettings or /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="container">
                    <PostCardFeed data={data} />
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allSanityPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query PostQuery($limit: Int!, $skip: Int!) {
    allSanityPost(
        sort: { order: DESC, fields: [publishedAt] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...SanityPostFields
        }
      }
    }
    allSanitySiteSettings {
        edges {
            node {
                authorSlug {
                    ...SlugWithRedirectValidationFields
                }
            }
        }
    }
  }
`
